import React, { useEffect, useState } from 'react';
import { Helmet, Script } from "react-helmet"

// import fav from '../../assets/images/fav.png';

const SeoMeta = ({ path }) => {

    // const [title,setTitle] = useState('Skup tonerów Warszawa centrum: pełnych niewykorzystanych, oryginalnych, pustych, zużytych - SprzedajToner.pl');
    const [keywords,setKeywords] = useState('tonery,skup,sprzedajtoner,samsung,canon');
    const [description,setDescription] = useState('Centrum skupu pełnych, niewykorzystanych tonerów na terenie całej Warszawy oraz Polski. EKSPRESOWO przygotujemy dla Ciebie wycenę. Sprawdź, jakie to proste.');

    const title = path => {
        switch (path){
            case '/marki.html': case '/marki': return 'Skup tonerów Canon - cena, Warszawa - SprzedajToner.pl';

            case '/marka-canon': case '/marka-canon.html': return 'Skup tonerów Canon - cena, Warszawa - SprzedajToner.pl';
            case '/marka-brother': case '/marka-brother.html': return 'Skup tonerów Brother - oryginalnych, pustych i pełnych - SprzedajToner.pl';
            case '/marka-hp': case '/marka-hp.html': return 'Skup oryginalnych tonerów HP - wycena produktu - SprzedajToner.pl';
            case '/marka-samsung': case '/marka-samsung.html': return 'Skup tonerów Samsung - oryginalnych, używanych i nieużywanych - SprzedajToner.pl';
            case '/marka-minolta': case '/marka-minolta.html': return 'Minolta - skup oryginalnych tonerów - Warszawa - SprzedajToner.pl';
            case '/marka-xerox': case '/marka-xerox.html': return 'Skup oryginalnych tonerów Xerox - pełnych i pustych - SprzedajToner.pl';
            case '/opinie': case '/opinie.html': return 'Opinie naszych klientów - SprzedajToner.pl';
            case '/kontakt': case '/kontakt.html': return 'Skontaktuj się z nami - SprzedajToner.pl';
            case '/privacy': return 'Polityka prywatności - SprzedajToner.pl';
            case '/rodo': return 'Rodo - SprzedajToner.pl';
            case '/faq': return 'FAQ - SprzedajToner.pl';
            case '/sprawdz_wplate.html': return 'Sprawdź wpłatę - SprzedajToner.pl';

            default: return 'Skup tonerów Warszawa centrum: pełnych niewykorzystanych, oryginalnych, pustych, zużytych - SprzedajToner.pl'; 
        }
    }
    

    return (
        <Helmet>

            <meta name="theme-color" content="#066791" />

            <meta charSet="utf-8" />
            <title>{title(path)}</title>
            {path === '/sprawdz_wplate' &&
                <meta name="robots" content="noindex, follow" />
            }
            <meta name="description" content={description} />
            {/* <meta name="keywords" content={keywords} /> */}
            <meta 
                name='viewport'
                content='minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no'
            />

            <Script
                src={`https://www.googletagmanager.com/gtag/js?id=GTM-MTBHGLF`}
                strategy="off-main-thread"
                forward={[`gtag`]}
            ></Script>
            <Script
                id="gtag-config"
                strategy="off-main-thread"
                dangerouslySetInnerHTML={{
                __html: `window.dataLayer = window.dataLayer || [];
                window.gtag = function gtag(){ window.dataLayer.push(arguments);}
                gtag('js', new Date()); 
                gtag('config', 'GTM-MTBHGLF', { send_page_view: false })`
                }}
            ></Script>
            

            <script type="application/ld+json">
                {`
                    {
                        "@context": "http://schema.org",
                        "@type": "LocalBusiness",
                        "address": {
                            "@type": "PostalAddress",
                            "addressLocality": "Warszawa",
                            "streetAddress": "Pola Karolińskie 4 lok. 2",
                            "postalCode": "02-401",
                            "addressRegion": "mazowieckie"
                        },
                        "name": "SprzedajToner.pl",
                        "email": "info@sprzedajtoner.pl",
                        "telephone": "602594898",
                        "vatID": "5252206809",
                        "image": "https://sprzedajtoner.pl/static/sprzedaj-toner-logo-3689e100d541b52b9e1183e1355c7085.png"
                    }
                `}
            </script>
            
        </Helmet>
    );
}

export default SeoMeta;