import axios from 'axios';

// export const API_URL = 'http://127.0.0.1:8001';
export const API_URL = 'https://sprzedajtoner.com.pl';

export const url = API_URL + '/api/auth/';
export const urlPhotos = API_URL + '/uploads/photos/';

export const urlRodo = '/rodo';

// const url = 'http://127.0.0.1:8000/api/auth/';

const api = (path,token,data,callBack) => {

    const headers={ 
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
        // 'Access-Control-Allow-Origin': '*',
    };
    if (token !== '' && token!==null){
        headers.Authorization = 'Bearer '+token;
    }

    axios.post(url+path,data, { headers, mode: "no-cors" , timeout: 30000 })
      .then(res => {
        callBack({ 
            result: true, 
            data: (res.data !== undefined ? res.data : null),
            commTime: (res.commTime !== undefined ? res.commTime : null),
            comm: (res.data.comm !== undefined ? res.data.comm : ''),
        });
      })
      .catch(error => {
        if (error.response==null){
            return false;
          }
          if (error.toString().indexOf('Error: timeout of')>=0){
            callBack({
                result: false, 
                data: null, 
                errors: [], 
                errCode: 99,
                comm: (error.data.comm !== undefined ? error.data.comm : 'Koniec czasu na wykonanie zapytania'),
            });
          } else {
            callBack({ 
                result: false, 
                data: null, 
                comm: error.response.data !== undefined ? error.response.data.comm : '', 
                errors: error.response.data !== undefined ? error.response.data.errors : [], 
                errCode: (error.response.data.errCode !== undefined ? error.response.data.errCode : 1),
                commTime: (error.commTime !== undefined ? error.commTime : null),
              });
          }
        
    });
}

export const API_FILE = (path, file, token, f) => {

    let body = new FormData();
    body.append('photo', file);
    if (file !== undefined) {
        body.append('Content-Type', file.type);
        body.append('MimeType', file.type);
    } else {
        f({ 
            result: false, 
            comm: 'Błędny format pliku',
        });
        return;
    }

    const options = {
        headers: {
            "Content-Type": "multipart/form-data",
            "cache-control": "no-cache",
            "processData": false,
            "contentType": false,
            "mimeType": "multipart/form-data",
            "Authorization" : 'Bearer ' + token,
        }
    };

    axios.post(url+path, body,options)
        .then(function (response) {
            f({ result: true, data: response.data });
        })
        .catch(function (error) {
            let Message = '';
            if (error.response?.data?.message !== undefined){
                Message = error.response.data.message;
            } else {
                Message = error.response?.data !== undefined ? error.response.data.comm : '';
            }

            f({ 
                result: false, 
                comm: Message,
                commTime: ((error.response?.data !== undefined && error.response?.data.commTime !== undefined) ? error.response?.data?.commTime : null),
            });
        });

}

export const API_FILE_MULTI = (path, files, token, f) => {

    let body = new FormData();
    files.forEach(file => {
        body.append('photos', file);
    });
    
    if (files.length>0) {
        const types = [];
        files.forEach(file => {
            if (!types.includes(file.type)){
                body.append('Content-Type', file.type);
                body.append('MimeType', file.type);
                types.push(file.type);
            }
        });
    } else {
        f({ 
            result: false, 
            comm: 'Nie znalazłem żadnych plików',
        });
        return;
    }

    const options = {
        headers: {
            "Content-Type": "multipart/form-data",
            // "cache-control": "no-cache",
            // "processData": false,
            // "contentType": false,
            // "mimeType": "multipart/form-data",
            "Authorization" : 'Bearer ' + token,
        }
    };

    axios.post(url+path, body,options)
        .then(function (response) {
            f({ result: true, data: response.data });
        })
        .catch(function (error) {
            let Message = '';
            if (error.response?.data?.message !== undefined){
                Message = error.response.data.message;
            } else {
                Message = error.response?.data !== undefined ? error.response.data.comm : '';
            }

            f({ 
                result: false, 
                comm: Message,
                commTime: ((error.response?.data !== undefined && error.response?.data.commTime !== undefined) ? error.response?.data?.commTime : null),
            });
        });

}

export default api;